<template>
  <div class="page-content">
    <div class="header-box mb-24">
      <div class="left">
        <span>类型：</span>
        <el-select
          class="mr-20"
          v-model="queryParams.Type"
          placeholder="全部"
          clearable
        >
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <span>日期：</span>
        <el-date-picker
          v-model="queryParams.Date"
          type="daterange"
          placeholder="选择日期"
          class="mr-20"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
          >
        </el-date-picker>
        <el-button type="primary" @click="initialize">搜索</el-button>
      </div>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      v-loading="loading"
      height="640px"
    >
      <template v-for="(item, index) in tableConfig">
        <el-table-column :key="index" :label="item.label" :prop="item.prop">
          <template slot-scope="scope">
            <div v-if="item.prop === 'ApplyStartDate'">
              {{ formartDate(scope.row["ApplyStartDate"], "all") }}
            </div>
            <div v-if="item.prop === 'Type'">
              <el-tag type="success">{{ scope.row["Type_V"] }}</el-tag>
            </div>
            <el-tag
              v-else-if="item.prop === 'Status'"
              class="tag"
              :type="scope.row[item.prop] | statusFilter"
            >
              {{ scope.row[item.prop] | statusTextFilter }}</el-tag
            >
            <div v-else>{{ scope.row[item.prop] }}</div>
          </template>
        </el-table-column>
      </template>
      <el-table-column label="操作" width="200px">
        <template slot-scope="scoped">
          <el-button
            type="text"
            icon="el-icon-edit-outline"
            class="btn-color"
            @click="handleApprove(scoped.row.Id)"
            v-if="scoped.row.Status !== 4 && scoped.row.Status !== 0"
            >审批</el-button
          >
          <el-button
            type="text"
            icon="el-icon-view"
            class="btn-color"
            @click="handleView(scoped.row.Id)"
            >查看</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="实践审批"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <div v-loading="dialogLoading">
        <el-row class="u-flex">
          <div class="mr-8">发起学生：</div>
          <div>{{ applyInfo.UserName }}</div>
        </el-row>
        <el-row class="u-flex">
          <div class="mr-8">发起时间：</div>
          <div>{{ applyInfo.ApplyTime }}</div>
        </el-row>
        <el-row class="u-flex">
          <div class="mr-8">申请类型：</div>
          <div>{{ applyInfo.Type_V }}</div>
        </el-row>
        <el-row class="u-flex" v-if="applyInfo.Type !== 2">
          <div class="mr-8">转岗岗位：</div>
          <div>
            {{ applyInfo.ApplyJobName }}
          </div>
        </el-row>
        <el-row class="u-flex">
          <div class="mr-8">申请原因：</div>
          <div>{{ applyInfo.Content }}</div>
        </el-row>
        <!-- <el-row class="u-flex">
        <div class="mr-8">附件：</div>
      </el-row> -->
        <el-row>
          <div class="mr-8">老师回复：</div>
          <el-input
            type="textarea"
            resize="none"
            class="u-flex-1"
            rows="3"
            v-model="opinion"
            :disabled="isView"
          ></el-input>
        </el-row>
      </div>
      <span slot="footer" class="form-footer">
        <el-button
          v-if="!isView"
          type="primary"
          @click="handleSubmit"
          :loading="btnLoading"
          >通过</el-button
        >
        <el-button
          v-if="!isView"
          type="danger"
          @click="handleReject"
          :loading="btnLoading"
          >驳回</el-button
        >
        <el-button @click="handleClose">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getPageList, getInfo, approve } from "@/api/internshipApply";
import contentTools from "@/mixins/content-tools";
import { StaffTypeEnum } from "@/utils/constant";
import { mapGetters } from "vuex";
export default {
  filters: {
    statusFilter(status) {
      const statusMap = {
        0: "danger",
        1: "",
        2: "warning",
        4: "success"
      };
      if (statusMap[status]) {
        return statusMap[status];
      } else {
        return "";
      }
    },
    statusTextFilter(status) {
      const statusMap = {
        0: "驳回",
        1: "已提交",
        2: "审批中",
        4: "审批通过"
      };
      if (statusMap[status]) {
        return statusMap[status];
      }
    }
  },
  mixins: [contentTools],
  data() {
    return {
      btnLoading: false,
      dialogVisible: false,
      dialogLoading: false,
      loading: false,
      queryParams: {
        PageSize: 999,
        PageIndex: 1
      },
      tableData: [],
      tableConfig: [
        {
          label: "学生姓名",
          prop: "UserName"
        },
        {
          label: "岗位",
          prop: "CurrentJobName"
        },
        {
          label: "申请类型",
          prop: "Type"
        },

        {
          label: "内容",
          prop: "Content"
        },
        {
          label: "发起时间",
          prop: "ApplyTime"
        },
        {
          label: "状态",
          prop: "Status"
        }
      ],
      typeList: [
        { label: "转岗", value: 1 },
        { label: "终止实践", value: 2 }
      ],
      applyInfo: "",
      opinion: "",
      isView: false
    };
  },
  computed: {
    ...mapGetters(["employee"]),
    RoleType() {
      const { matched } = this.$route;
      if (matched.some(({ path }) => path.includes("enterprise"))) {
        return StaffTypeEnum.Enterprise;
      } else if (matched.some(({ path }) => path.includes("school"))) {
        return StaffTypeEnum.School;
      }
      return undefined;
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      try {
        this.loading = true;
        if (this.RoleType === StaffTypeEnum.School) {
          let temp = this.employee.find(el => el.StaffType === this.RoleType);
          this.queryParams.SchoolId = temp.Employer.Id;
        } else if (this.RoleType === StaffTypeEnum.Enterprise) {
          let temp = this.employee.find(el => el.StaffType === this.RoleType);
          this.queryParams.EnterpriseId = temp.Employer.Id;
        } else {
          return null;
        }
        await this.getTableData();
      } catch (err) {
        console.log("err", err);
      } finally {
        this.loading = false;
      }
    },
    async getTableData() {
      if (this.queryParams.Date) {
        let [startTime, endTime] = this.queryParams.Date;
        this.queryParams.StartTime = startTime;
        this.queryParams.EndTime = endTime;
      } else {
        this.queryParams.StartTime = "";
        this.queryParams.EndTime = "";
      }
      const res = await getPageList(this.queryParams);
      if (res.IsSuccess) {
        this.tableData = res.Result.Data;
      }
    },
    async handleSubmit() {
      try {
        this.btnLoading = true;
        let params = {};
        params.Id = this.applyInfo.Id;
        params.IsApproved = true;
        params.Opinion = this.opinion;
        const res = await approve(params);
        if (res.IsSuccess) {
          this.$message.success("审批成功");
          this.handleClose();
          this.initialize();
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.btnLoading = false;
      }
    },
    async handleReject() {
      try {
        this.btnLoading = true;
        let params = {};
        params.Id = this.applyInfo.Id;
        params.IsApproved = false;
        params.Opinion = this.opinion;
        const res = await approve(params);
        if (res.IsSuccess) {
          this.$message.success("审批成功");
          this.handleClose();
          this.initialize();
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.btnLoading = false;
      }
    },
    async getApplyInfo(id) {
      try {
        this.dialogLoading = true;
        const res = await getInfo(id);
        if (res.IsSuccess) {
          this.applyInfo = res.Result;
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.dialogLoading = false;
      }
    },
    handleApprove(id) {
      this.isView = false;
      this.dialogVisible = true;
      this.getApplyInfo(id);
    },
    handleView(id) {
      this.isView = true;
      this.dialogVisible = true;
      this.getApplyInfo(id);
    },
    handleClose() {
      this.dialogVisible = false;
      this.opinion = "";
    }
  }
};
</script>

<style lang="scss" scoped>
.header-box {
  display: flex;
  justify-content: space-between;
}
.el-row {
  display: flex;
  margin: 16px 0;
}
</style>
